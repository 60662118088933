const spanishTranslation = {
  // Header
  'profile-icon_my-profile': 'Mi perfil',
  'profile-icon_login': 'Iniciar sesión',
  'profile-icon_logout': 'Cerrar sesión',
  'profile-icon_send-email-verification': 'Enviar email de verificación',
  // Post Media Endpoint
  'post-media-endpoint_description-1': 'Para organizar archivos en carpetas, incluimos en la URL los nombres de las carpetas separados por guiones',
  'post-media-endpoint_description-1-nested': 'anidado',
  'post-media-endpoint_description-2': 'La clave del archivo es',
  'post-media-endpoint_codeblock-archive': 'archivo',
  'post-media-endpoint_codeblock-folders': 'imagenes-usuarios',
  'post-media-endpoint_codeblock-created-success': 'Archivo/s creado correctamente',
  'media-endpoint_instructions-label': 'Instrucciones',
  'media-endpoint_requests-title': 'Petición',
  'media-endpoint_response-title': 'Respuesta',
  'post-media-endpoint_codeblock-deleted-success': 'Archivo eliminado correctamente',
  'post-media-endpoint_codeblock-get-license-token-success': 'Token obtenido correctamente',
  // Licenses
  'licenses_empty-msg': 'No hay licencias',
  'licenses_tooltip_license-not-active': 'La licencia no está activa',
  'licenses_tooltip_license-not-online': 'La licencia está activa pero no está online',
  'licenses_tooltip_subscription-not-active': 'La licencia está activa y online pero la suscripción está desactivada',
  'licenses_tooltip_license-subscription-online-active': 'La licencia está activa, online y la suscripción está activada',
  'licenses_tooltip_project-name': 'Nombre del proyecto',
  'licenses_tooltip_last-update': 'Última subida de datos',
  'licenses_tooltip_activate-license': 'Activar licencia',
  'licenses_tooltip_deactivate-license': 'Desactivar licencia',
  'licenses_tooltip_used-disk': 'ocupado',
  'licenses_tooltip_used-requests': 'usado',
  'licenses_tooltip_total-available-disk': 'Disco total disponible',
  'licenses_tooltip_max-requests-month': 'Máximo de peticiones al mes',
  'licenses_max-requests-month': 'mes',
  'licenses_used-disk': 'Disco usado',
  'licenses_requests': 'Peticiones',
  'licenses_subscription': 'Suscripción',
  'licenses_archives': 'Archivos',
  'licenses_created-at': 'Fecha de creación',
  'licenses_valid-until': 'Válido hasta',
  'licenses_remove': 'Eliminar',
  'licenses_license': 'licencia',
  'licenses_refresh-token': 'Refrescar token',
  'licenses_get-media-token': 'Copiar token',
  'licenses_title': 'Mis Licencias',
  'licenses_create-license_project-name_error': 'El nombre del proyecto solo puede contener letras, números, guiones y guiones bajos',
  'licenses_create-license_input-placeholder': 'Introduce el nombre de la licencia',
  'licenses_create-license_btn': 'Crear Licencia',
  'licenses_create-license_close-input': 'Cerrar',
  'licenses_create-license_create-license-btn': 'Crear Licencia',
  'licenses_delete-license-popover_title': 'Eliminar licencia',
  'licenses_delete-license-popover_description-1': '¿Estás seguro de que quieres eliminar la licencia',
  'licenses_delete-license-popover_description-2': 'y todos los archivos?',
  'licenses_delete-license-popover_cancel': 'Cancelar',
  'licenses_delete-license-popover_delete': 'Eliminar',

  // ACTIONS /////////////////////////////////
  // Licenses
  'actions_licenses_get-licenses_error': 'Error al obtener las licencias',
  'actions_licenses_post-license_error': 'Error al crear la licencia',
  'actions_licenses_set-license-online_error': 'Error al cambiar el estado de la licencia',
  'actions_licenses_delete-license_error': 'Error al cambiar el estado de la licencia',
  'actions_licenses_get-license-token_success': 'Token copiado correctamente',
  'actions_licenses_get-license-token_error': 'Error al obtener el token',
  'actions_licenses_refresh-license-token_success': 'Token refrescado y copiado correctamente',
  ////////////////////////////////////////////
}

export default spanishTranslation;